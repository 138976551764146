<template>
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <div class="flexAC">
        <template v-if="roleBtn[0].power">
          <div class="el-icon-plus settingAdd" @click="handAdd"><span> 新增</span></div>
        </template>
        <template v-if="roleBtn[3].power">
          <div class="settingBlue" style="margin: 0px 16px;" @click="handUpAndLow(true, '上架')"><span>上架</span></div>
          <div class="settingExport" @click="handUpAndLow(false, '下架')"><span>下架</span></div>
        </template>
      </div>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-select v-model="sgid_one" placeholder="请选择一级分组" @change="oneChange" class="retSelect">
          <el-option v-for="item in sgOneList" :key="item.index" :label="item.sgname" :value="item.sgid"></el-option>
        </el-select>
        <el-select v-model="sgid_two"  placeholder="请选择二级分组" class="retSelect">
          <el-option v-for="item in sgTwoList" :key="item.index" :label="item.sgname" :value="item.sgid"></el-option>
        </el-select>
        <el-button type="primary" class="btnSearch"  @click="handleSearch">搜索</el-button>
        <el-button class="btnReset"  @click="handleReset">重置</el-button>
      </div>
      <div class="table_list" style="margin-top: 16px;">
        <el-table ref="serviceTable"
          :data="serviceList"
          :row-key="getRowKeys"
          @row-click-stop="handleRowClick"
          @selection-change="selectionChange"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            type="selection"
            prop="serveid"
            :reserve-selection="true">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="服务名称"
            prop="servename">
          </el-table-column>
          <el-table-column
            label="价格"
            prop="serveprice">
          </el-table-column>
          <el-table-column
            label="上架状态"
            prop="ishelf">
            <template slot-scope="scope">
              <span v-if="scope.row.ishelf === true">已上架</span>
              <span v-if="scope.row.ishelf === false">已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            label="可售门店"
            prop="shop_num">
          </el-table-column>
          <el-table-column
            label="服务分组"
            prop="sgname_one">
            <template slot-scope="scope">
              <span>{{ scope.row.sgname_one }}</span>
              <span v-if="scope.row.sgname_two !== ''">/{{ scope.row.sgname_two }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="排序"
            prop="servesort">
            <template slot-scope="scope">
              <el-button type="text" style="color:#3C84EF" size="small" @click.stop="changeSort(scope.row)">{{scope.row.servesort}}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small" @click="handEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[1].power">
                <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <div class="box100" v-if="showForm">
      <ServeForm :serveInfoEdit="serveInfoEdit" @formCancel="formCancel" @formSuccess="formSuccess"></ServeForm>
    </div>
    <!-- 服务排序弹窗 -->
    <el-dialog title="排序" :visible.sync="sortShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="sortCancel">
      <el-form :model="sortForm" :rules="sortRule" ref="sortLabel">
        <el-form-item label="排序" prop="sort">
          <el-select v-model="sortForm.sort" placeholder="下拉选择排序" style="width: 100%;" >
            <el-option v-for="item in sortList" :key="item.id" :label="item.sort" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="sortCancel">取消</div>
        <div class="settingConfirm" @click="sortConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ServeForm from '@/components/service/serveForm'
import { serveListQuery, serveListUpAndLow, serveInfoDel, serveGroupSD, serveGroupLevel, serveSortGet, serveSortUpdate } from '@/api/service'
export default {
  components: {
    ServeForm
  },
  props: ['roleBtn'],
  data () {
    return {
      showForm: false,
      page: 1,
      size: 10,
      total: 0,
      serviceList: [],
      bindIdList: [],
      serveInfoEdit: {},
      sgid_one: '',
      sgid_two: '',
      sgOneList: [],
      sgTwoList: [],
      sortShow: false,
      sortForm: {sort: ''},
      sortRule: {sort: [{ required: true, trigger: 'change', message: '请选择排序' }]},
      sortList: [],
      serveId: ''
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        sgid_one: this.sgid_one,
        sgid_two: this.sgid_two
      }
      serveListQuery(params).then(res => {
        if (res.status === 1) {
          this.serviceList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 服务分组一级下拉
    getInit() {
      serveGroupSD().then(res => { 
        if (res.status === 1) {
          this.sgOneList = res.data.data
        }
      })
    },
    // 服务一级分组选择
    oneChange (val) {
      this.sgTwoList = []
      this.sgid_two = ''
      serveGroupLevel({ sgid: val }).then(res => {
        if (res.status === 1) {
          this.sgTwoList = res.data.data
        }
      })
    },
    // 排序弹窗
    changeSort (row) {
      this.sortShow = true
      this.serveId = row.serveid
      this.sortForm.sort = row.serveid
      serveSortGet({ serveid: row.serveid }).then(res => {
        if (res.status === 1) {
          this.sortList = res.data.data
        } else {
          this.sortList = []
        }
      })
    },
    // 排序[取消]
    sortCancel () {
      this.sortShow = false
      this.$refs.sortLabel.resetFields()
    },
    // 排序[确定]
    sortConfirm () {
      this.$refs.sortLabel.validate((valid) => {
        if (!valid) return
        const params = {
          id: this.sortForm.sort,
          serveid: this.serveId,
          sgid_one: this.sgid_one,
          sgid_two: this.sgid_two
        }
        serveSortUpdate(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.sortCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    handleSearch () {
      this.page = 1
      this.init()
      this.getInit()
    },
    handleReset () {
      this.page = 1
      this.sgid_one = ''
      this.sgid_two = ''
      this.sgTwoList = []
      this.init()
      this.getInit()
    },
    pageChange (page) {
      this.page = page
      this.init()
      this.getInit()
    },
    getRowKeys (row) {
      return row.serveid
    },
    handleRowClick (row, column, event) {
      this.$refs.serviceTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) { this.bindIdList = [] } else { this.bindIdList = val }
    },
    // 点击新增
    handAdd () {
      this.showForm = true
      this.serveInfoEdit = null
    },
    // 点击编辑[展示]
    handEdit (row) {
      this.showForm = true
      this.serveInfoEdit = { ...row }
    },
    // 取消表单提交
    formCancel () {
      this.showForm = false
    },
    // 表单提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 服务[上下架]
    handUpAndLow (type, label) {
      if (this.bindIdList.length === 0) {
        this.$message.warning('请选择服务')
      } else {
        const arr = []
        for (let i = 0; i < this.bindIdList.length; i++) {
          arr.push(this.bindIdList[i].serveid)
        }
        this.$confirm('是否对服务进行' + label + '操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          serveListUpAndLow({ serveid: arr, ishelf: type }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$nextTick(() => {
                this.$refs.serviceTable.clearSelection(this.bindIdList)
              })
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    // 服务删除
    handDel (row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '删除服务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        serveInfoDel({ serveid: row.serveid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style>

</style>
