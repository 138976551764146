<template>
  <div class="box100">
    <div class="settingInfoBox">
      <div class="table_list">
        <el-table ref="serveStore"
          :row-key="getRowKeys"
          @row-click="handleRowClick"
          @selection-change="selectionChange"
          :data="storeList">
          <el-table-column
            type="selection"
            prop="shopid">
          </el-table-column>
          <el-table-column
            label="门店编号"
            prop="shopcode">
          </el-table-column>
          <el-table-column
            label="区域"
            prop="province_name,city_name,areas_name">
            <template slot-scope="scope"> {{scope.row.province_name}}{{scope.row.city_name}} {{scope.row.areas_name}}</template>
          </el-table-column>
          <el-table-column
            label="门店名称"
            prop="shopname">
          </el-table-column>
          <el-table-column
            label="负责人"
            width="80"
            prop="shopuser">
          </el-table-column>
          <el-table-column
            label="联系电话"
            prop="superphone">
          </el-table-column>
          <el-table-column
            label="门店类型"
            width="80"
            prop="shoptype">
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="infoCancel">取消</div>
      <div class="settingConfirm" @click="infoConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import { serveStoreQuery, serveStoreEdit } from '@/api/service'

export default {
  name: 'serveStoreForm',
  props: ['serveInfoEdit'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      storeList: [],
      bindIdList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        serveid: this.serveInfoEdit.serveid,
        page_index: this.page,
        page_size: this.size
      }
      serveStoreQuery(params).then(res => {
        if (res.status === 1) {
          this.storeList = res.data.data
          this.total = res.data.recordcount
          this.$nextTick(() => {
            for (let i = 0; i < this.storeList.length; i++) {
              if (this.storeList[i].ischoose === 1) {
                this.$refs.serveStore.toggleRowSelection(this.storeList[i])
              }
            }
          })
        }
      })
    },
    getRowKeys (row) {
      return row.shopid
    },
    handleRowClick (row, column, event) {
      this.$refs.serveStore.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) { this.bindIdList = [] } else { this.bindIdList = val }
    },
    // 取消
    infoCancel () {
      this.$emit('HandCancel')
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    infoConfirm () {
      if (this.bindIdList.length === 0) {
        this.$message.warning('请选择门店')
      } else {
        const arr = []
        for (let i = 0; i < this.bindIdList.length; i++) {
          arr.push(this.bindIdList[i].shopid)
        }
        this.$confirm('是否对该服务设置可售门店?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          serveStoreEdit({ serveid: this.serveInfoEdit.serveid, shopid: arr }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>

</style>
