<template>
  <div class="box100">
    <el-form class="settingInfoBox" :model="serveInfo" :rules="serveInfoRule" ref="serveInfoForm">
      <div class="formClass">
        <p class="formTitle">基本信息</p>
        <div class="flexCB">
          <el-form-item label="服务名称" class="detailFormItem" prop="servename">
            <el-input v-model="serveInfo.servename"  placeholder="请输入服务名称"></el-input>
          </el-form-item>
          <el-form-item label="服务时长" class="detailFormItem" prop="servetlong">
            <el-input v-model="serveInfo.servetlong"  placeholder="请输入服务时长">
              <span slot="append">分钟</span>
            </el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="部位" class="detailFormItem" prop="serveparts">
            <el-input v-model="serveInfo.serveparts"  placeholder="请输入部位"></el-input>
          </el-form-item>
          <el-form-item label="服务功效" class="detailFormItem" prop="sefname">
            <el-input v-model="serveInfo.sefname"  placeholder="请输入服务功效"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <div class="detailFormItem" style="margin-bottom: 0px;">
            <el-form-item label="服务流程" class="labelItem" prop="sefproc">
              <el-input v-model="LcKey"  placeholder="请输入服务流程" style="width: calc(100% - 70px);"></el-input>
              <p class="zdyAddBtn" @click="tagAdd('1')">添加</p>
            </el-form-item>
            <div :class="serveInfo.sefproc.length === 0 ? '': 'tagBox'">
              <p class="seTag" v-for="(tag,index) in serveInfo.sefproc" :key="tag">
                <el-tag closable type="info" @close="tagDel('1',index)">
                  {{index+1}}.{{tag}}
                </el-tag>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">运营设置</p>
        <div class="flexCB">
          <el-form-item label="销售价" class="detailFormItem" prop="serveprice">
            <el-input v-model="serveInfo.serveprice" placeholder="请输入销售价" @input.native="changeInputP($event, serveInfo.serveprice)"></el-input>
          </el-form-item>
          <el-form-item label="划线价" class="detailFormItem fiLabelLeft" prop="lineprice">
            <el-input v-model="serveInfo.lineprice" placeholder="请输入划线价" @input.native="changeInputP($event, serveInfo.lineprice)" class="sfInput"></el-input>
            <el-radio v-model="serveInfo.isline" :label="true">显示</el-radio>
            <el-radio v-model="serveInfo.isline" :label="false">隐藏</el-radio>
          </el-form-item>
        </div>
        <div class="flexCB">
          <div class="flexCB detailFormItem">
            <el-form-item label="服务分组" class="detailFormItem" prop="sgid_one">
              <el-select v-model="serveInfo.sgid_one" placeholder="请选择一级分组" @change="oneChange">
                <el-option v-for="item in sgOneList" :key="item.index" :label="item.sgname" :value="item.sgid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="detailFormItem" prop="sgid_two" style="margin-bottom: 0px;">
              <el-select v-model="serveInfo.sgid_two" placeholder="请选择二级分组">
                <el-option v-for="item in sgTwoList" :key="item.index" :label="item.sgname" :value="item.sgid"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="活动标签" class="detailFormItem" prop="slid">
            <el-select v-model="serveInfo.slid" placeholder="请选择活动标签" style="width: 100%;">
              <el-option v-for="item in labelList" :key="item.index" :label="item.slname" :value="item.slid"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="排序" class="detailFormItem" prop="slid">
            <el-select v-model="serveInfo.slid" placeholder="请选择排序方式" style="width: 100%;">
              <el-option v-for="item in labelList" :key="item.index" :label="item.slname" :value="item.slid"></el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <div class="detailFormItem">
          <el-form-item label="服务标签(最多添加3个标签)" class="labelItem" prop="servelabel">
            <el-input v-model="FwKey"  placeholder="请输入服务标签" style="width: calc(100% - 70px);"></el-input>
            <p class="zdyAddBtn" @click="tagAdd('2')">添加</p>
          </el-form-item>
          <div class="flexAC" :class="serveInfo.servelabel.length === 0 ? '': 'tagBox'">
            <el-tag v-for="(tag,index) in serveInfo.servelabel" :key="tag" closable type="info" @close="tagDel('2',index)">{{tag}}</el-tag>
          </div>
        </div>
      </div>
      <div class="formClass">
        <p class="formTitle">服务图片</p>
        <template v-if="serveInfoEdit === null">
          <el-form-item label="服务主图" class="labelItem" prop="serveimg" style="margin-bottom: 20px;">
            <div style="width: 25%;margin-bottom: 10px;" v-for="(item, index) in 4" :key="index">
              <Upload :fileType="'jpg/png/jpeg'" :picture="item.furl" @uploadSuccess="ImgSuccess"></Upload>
            </div>
          </el-form-item>
          <el-form-item label="服务详图" class="labelItem" prop="servedimg">
            <div style="width: 25%;margin-bottom: 10px;" v-for="(item, index) in 4" :key="index">
              <Upload :fileType="'jpg/png/jpeg'" :picture="item.furl" @uploadSuccess="ImgSuccessT"></Upload>
            </div>
          </el-form-item>
        </template>
        <template v-if="serveInfoEdit !== null">
          <el-form-item label="服务主图" class="labelItem" prop="serveimg" style="margin-bottom: 20px;">
            <div style="width: 25%;margin-bottom: 10px;" v-for="(item, index) in serveInfo.serveimg" :key="index" @click="getIndex(index)">
              <Upload :fileType="'jpg/png/jpeg'" :picture="item.furl" @uploadSuccess="ImgEditSuccess"></Upload>
            </div>
          </el-form-item>
          <el-form-item label="服务详图" class="labelItem" prop="servedimg">
            <div style="width: 25%;margin-bottom: 10px;" v-for="(item, index) in serveInfo.servedimg" :key="index" @click="getIndexT(index)">
              <Upload :fileType="'jpg/png/jpeg'" :picture="item.furl" @uploadSuccess="ImgEditSuccessT"></Upload>
            </div>
          </el-form-item>
        </template>
      </div>
    </el-form>

    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="infoCancel">取消</div>
      <div class="settingConfirm" @click="infoConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { serveGroupLevel, serveGroupSD, serveLabelDown, serveInfoAdd, serveInfoQuery, serveInfoEdit } from '@/api/service'
export default {
  name: 'serveInfoForm',
  components: {
    Upload
  },
  props: ['serveInfoEdit'],
  data () {
    const checkGroup = (rule, value, callback) => {
      if (this.serveInfo.sgid_one !== '') {
        if (this.sgTwoList.length !== 0) {
          if (value === '') {
            return callback(new Error('请选择二级分组'))
          }
        }
      }
      callback()
    }
    const moneyReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    return {
      serveInfo: {
        serveid: '',
        servename: '',
        servetlong: '',
        serveparts: '',
        sefname: '',
        sefproc: [], // 流程
        serveprice: '', // 销售价
        lineprice: '', // 原价
        isline: true, // 是否显示划线
        sgid_one: '',
        sgid_two: '',
        servesort: '',
        servelabel: [], // 服务标签
        slid: '', // 活动标签id
        serveimg: [], // 服务主图
        servedimg: [] // 服务详图
      },
      serveInfoRule: {
        servename: [
          { required: true, trigger: 'blur', message: '服务名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        servetlong: { required: true, trigger: 'blur', message: '服务时长不能为空' },
        serveparts: [
          { required: true, trigger: 'blur', message: '服务部位不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sefname: [
          { required: true, trigger: 'blur', message: '服务功效不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sefproc: { required: true, trigger: 'blur', message: '请添加服务流程' },
        serveprice: [{ required: true, trigger: 'blur', message: '销售价不能为空' }],
        lineprice: [{ required: true, trigger: 'blur', message: '划线价不能为空' }],
        sgid_one: { required: true, trigger: 'blur', message: '请选择一级分组' },
        // sgid_two: { required: true, trigger: 'blur', message: '请选择二级分组' },
        sgid_two: { required: true, validator: checkGroup, trigger: 'blur' },
        // slid: { required: true, trigger: 'blur', message: '请选择活动标签' },
        servelabel: { required: true, trigger: 'blur', message: '请添加服务标签' },
        serveimg: { required: true, trigger: 'blur', message: '请上传服务主图' },
        servedimg: { required: true, trigger: 'blur', message: '请上传服务详图' }
      },
      LcKey: '',
      FwKey: '',
      sgOneList: [],
      sgTwoList: [],
      labelList: [],
      imgIndex: 0,
      imgIndexT: 0
    }
  },
  created () {
    this.getDown()
  },
  methods: {
    // 下拉选择框
    getDown () {
      serveGroupSD().then(res => { // 一级分组
        if (res.status === 1) {
          this.sgOneList = res.data.data
          if (this.serveInfoEdit !== null) {
            serveInfoQuery({ serveid: this.serveInfoEdit.serveid }).then(res => {
              if (res.status === 1) {
                this.serveInfo = res.data.data[0]
                this.oneChangeEdit(this.serveInfo.sgid_one)
              }
            })
          }
        }
      })
      serveLabelDown().then(res => { // 活动标签
        if (res.status === 1) {
          this.labelList = res.data.data
        }
      })
    },
    // 添加标签
    tagAdd (type) {
      if (type === '1') {
        if (this.LcKey !== '') {
          if (this.serveInfo.sefproc.length < 10) {
            this.serveInfo.sefproc.push(this.LcKey)
            this.LcKey = ''
          }
        }
      } else if (type === '2') {
        if (this.FwKey !== '') {
          if (this.serveInfo.servelabel.length < 3) {
            this.serveInfo.servelabel.push(this.FwKey)
            this.FwKey = ''
          }
        }
      }
    },
    // 删除标签
    tagDel (type, eq) {
      if (type === '1') {
        this.serveInfo.sefproc.splice(eq, 1)
      } else if (type === '2') {
        this.serveInfo.servelabel.splice(eq, 1)
      }
    },
    // 价格
    changeInputP (e, val) {
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
      val = e.target.value
    },
    // 服务一级分组选择
    oneChange (val) {
      this.sgTwoList = []
      this.serveInfo.sgid_two = ''
      serveGroupLevel({ sgid: val }).then(res => {
        if (res.status === 1) {
          this.sgTwoList = res.data.data
        }
      })
    },
    // 服务二级分组获取【编辑】
    oneChangeEdit (val) {
      serveGroupLevel({ sgid: val }).then(res => {
        if (res.status === 1) {
          this.sgTwoList = res.data.data
        }
      })
    },
    getIndex (index) { this.imgIndex = index },
    getIndexT (index) { this.imgIndexT = index },
    ImgSuccess (res) {
      const obj = { fid: res.fid, furl: res.furl }
      this.serveInfo.serveimg.push(obj)
    },
    ImgSuccessT (res) {
      const obj = { fid: res.fid, furl: res.furl }
      this.serveInfo.servedimg.push(obj)
    },
    ImgEditSuccess (res) {
      this.serveInfo.serveimg[this.imgIndex].fid = res.fid
      this.serveInfo.serveimg[this.imgIndex].furl = res.furl
    },
    ImgEditSuccessT (res) {
      this.serveInfo.servedimg[this.imgIndexT].fid = res.fid
      this.serveInfo.servedimg[this.imgIndexT].furl = res.furl
    },
    // 取消
    infoCancel () {
      this.$emit('HandCancel')
    },
    // 基本信息提交
    infoConfirm () {
      this.$refs.serveInfoForm.validate((valid) => {
        if (!valid) return
        const params = {
          serveid: this.serveInfo.serveid, // 服务id
          servename: this.serveInfo.servename, // 服务名称
          servetlong: this.serveInfo.servetlong, // 服务时长
          serveparts: this.serveInfo.serveparts, // 服务部位
          sefname: this.serveInfo.sefname, // 服务功效
          sefproc: this.serveInfo.sefproc, // 服务流程
          serveprice: this.serveInfo.serveprice, // 销售价
          lineprice: this.serveInfo.lineprice, // 划线价
          isline: this.serveInfo.isline, // 是否显示
          sgid_one: this.serveInfo.sgid_one, // 一级分组id
          sgid_two: this.serveInfo.sgid_two, // 二级分组id
          servesort: this.serveInfo.servesort, // 排序
          servelabel: this.serveInfo.servelabel, // 服务标签
          slid: this.serveInfo.slid, // 活动标签id
          serveimg: this.serveInfo.serveimg, // 服务主图
          servedimg: this.serveInfo.servedimg // 服务详图
        }
        if (this.serveInfoEdit === null) { // 新增提交
          serveInfoAdd(params).then(res => {
            if (res.status === 1) {
              // this.$confirm('新增服务管理基本信息成功，请尽快完善“服务设置” “可售门店设置”信息', '保存成功', {
              //   confirmButtonText: '确定',
              //   cancelButtonText: '',
              //   type: 'warning',
              //   center: true
              // }).then(() => {
              //   this.$emit('formSuccess')
              // }).catch(() => {
              //   this.$emit('HandCancel')
              // })
              this.$alert('新增服务管理基本信息成功，请尽快完善“服务设置” “可售门店设置”信息', '保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$emit('formSuccess')
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          serveInfoEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.seTag{
  line-height: 30px;
  margin-bottom: 5px;
}
.seTag:last-child{
  margin-bottom: 0px;
}
.seTag >>> .el-tag{
  height: auto;
  white-space: pre-wrap;
  margin-right: 0px;
}
.sfInput{
  width: 50%;
  margin-right: 30px;
}
</style>
