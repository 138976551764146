<template>
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="serveFormType === '0' ? 'sfActive': ''" @click="changeForm('0')">基本信息</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '1' ? 'sfActive': ''" @click="changeForm('1')">服务设置</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '2' ? 'sfActive': ''" @click="changeForm('2')">可售门店设置</div>
    </div>
    <div class="specialFR">
      <!-- 基本信息 -->
      <div class="formItemType" v-if="serveFormType === '0'">
        <ServeInfoForm :serveInfoEdit="serveInfoEdit" @HandCancel="HandCancel" @formSuccess="formSuccess"></ServeInfoForm>
      </div>

      <!-- 服务设置 -->
      <div class="formItemType" v-if="serveFormType === '1'">
        <el-form class="settingInfoBox" :model="serveSetupForm" :rules="serveSetupRule" ref="settingForm">
          <div class="formClass">
            <p class="formTitle">支付方式</p>
            <el-form-item label="付款方式" class="fiLabelLeft" prop="servepay" style="margin-bottom: -10px;">
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="1">微信</el-checkbox>
                <el-checkbox :label="2">现金</el-checkbox>
                <el-checkbox :label="3">余额</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="formClass">
            <p class="formTitle">计费方式</p>
            <el-form-item label="计费方式" class="fiLabelLeft" prop="billway" style="margin-bottom: -10px;">
              <el-radio v-model="serveSetupForm.billway" :label="1">按数量计费</el-radio>
            </el-form-item>
          </div>
          <div class="formClass">
            <div class="formTitle">购买须知 <p class="zdyP" @click="tagAdd('1')">自定义添加</p></div>
            <div class="zdyXzBox">
              <div class="zdyRow" v-for="(item, index) in serveSetupForm.servemark" :key="item.index">
                <el-input v-model="item.title"  placeholder="请输入标题(最多6字)" maxlength="6" class="zdyLabel"></el-input>
                <span class="surDel" @click="tagDel('1', index)">删除</span>
                <el-input class="formTextarea" v-model="item.info" type="textarea" maxlength="200" placeholder="请输入内容(最多200字)"></el-input>
              </div>
            </div>
          </div>
          <div class="formClass">
            <p class="formTitle">售后服务</p>
            <div class="detailFormItem">
              <el-form-item label="服务标签(最多添加3个标签)" class="labelItem" prop="serveafter">
                <el-input v-model="afterKey"  placeholder="请输入服务标签" style="width: calc(100% - 70px);"></el-input>
                <p class="zdyAddBtn" @click="tagAdd('2')">添加</p>
              </el-form-item>
              <div class="flexAC tagBox">
                <el-tag v-for="(tag,index) in serveSetupForm.serveafter" :key="tag" closable type="info" @close="tagDel('2',index)">{{tag}}</el-tag>
              </div>
            </div>
          </div>
          <div class="formClass">
            <p class="formTitle">其他设置</p>
            <div class="flexCB" style="margin-bottom: 20px;">
              <div class="detailFormItem">
                <p class="tip"><span>*</span> 提成方式</p>
                <div class="flexCB">
                  <el-radio v-model="serveSetupForm.serveway.comMethod" :label="'0'" style="line-height: 40px;">固定提成</el-radio>
                  <el-input v-model="serveSetupForm.serveway.comNumber"  placeholder="请输入提成金额"></el-input>
                </div>
              </div>
              <div class="detailFormItem">
                <p class="tip"><span>*</span> 上架时间</p>
                <el-radio v-model="serveSetupForm.serveway.upTime" :label="'1'" style="line-height: 40px;">立即上架售卖</el-radio>
                <el-radio v-model="serveSetupForm.serveway.upTime" :label="'0'" style="line-height: 40px;">暂不上架</el-radio>
              </div>
            </div>
            <div class="detailFormItem spcItem">
              <p class="tip"><span>*</span> 限每人购买数量</p>
              <p class="typeRadio"><el-radio v-model="serveSetupForm.serveway.buyType" :label="'0'">无限购</el-radio></p>
              <p class="typeRadio flexAC">
                <el-radio v-model="serveSetupForm.serveway.buyType" :label="'1'">限终身购买数量</el-radio>
                <el-input v-model="serveSetupForm.serveway.buyNumber" :disabled="serveSetupForm.serveway.buyType !== '1'" placeholder="请输入购买数量"></el-input>
              </p>
              <p class="typeRadio flexAC">
                <el-radio v-model="serveSetupForm.serveway.buyType" :label="'2'">用户单次购买数量</el-radio>
                <el-input v-model="serveSetupForm.serveway.buyNumber" :disabled="serveSetupForm.serveway.buyType !== '2'" placeholder="请输入购买数量"></el-input>
              </p>
            </div>
          </div>
        </el-form>
        <!--  提交按钮  -->
        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="HandCancel">取消</div>
          <div class="settingConfirm" @click="handSetConfirm">确定</div>
        </div>
      </div>

      <!-- 可售门店设置 -->
      <div class="formItemType" v-if="serveFormType === '2'">
        <ServeStoreForm :serveInfoEdit="serveInfoEdit" @HandCancel="HandCancel" @formSuccess="formSuccess"></ServeStoreForm>
      </div>
    </div>
  </div>
</template>

<script>
import ServeInfoForm from '@/components/service/serveInfoForm'
import ServeStoreForm from '@/components/service/serveStoreForm'
import { serveSetQuery, serveSetEdit } from '@/api/service'
export default {
  name: 'serveForm',
  components: {
    ServeInfoForm, // 基本信息
    ServeStoreForm // 可售门店
  },
  props: ['serveInfoEdit'],
  data () {
    return {
      serveFormType: '0',
      serveSetupForm: {
        serveway: {
          comMethod: '0',
          comNumber: '',
          upTime: '1',
          buyType: '0',
          buyNumber: ''
        }
      },
      serveSetupRule: {
        servepay: { required: true, trigger: 'blur', message: '选择支付方式' },
        billway: { required: true, trigger: 'blur', message: '选择计费方式' },
        serveafter: { required: true, trigger: 'blur', message: '请添加服务标签' }
      },
      afterKey: '',
      checkList: []
    }
  },
  methods: {
    changeForm (type) {
      this.serveFormType = type
      if (type === '1') { // 服务设置
        if (this.serveInfoEdit !== null) {
          serveSetQuery({ serveid: this.serveInfoEdit.serveid }).then(res => {
            if (res.status === 1) {
              this.serveSetupForm = res.data.data[0]
              this.checkList = this.serveSetupForm.servepay
              if (this.serveSetupForm.serveway.comMethod === undefined) {
                this.$nextTick(() => {
                  // this.checkList = this.serveSetupForm.servepay
                  this.serveSetupForm.serveway = {
                    comMethod: '0', // 提成方式 默认0
                    comNumber: '', // 提成金额
                    upTime: '1', // 上架时间 1立即上架 0暂不上架
                    buyType: '0', // 限购 0无限购1终身购买数量2用户单次购买
                    buyNumber: '' // 限购次数
                  }
                })
              }
            }
          })
        }
      }
    },
    // 取消
    HandCancel () {
      this.$emit('formCancel')
    },
    // 确定
    formSuccess () {
      this.$emit('formSuccess')
    },
    tagAdd (type) {
      if (type === '1') {
        const obj = { title: '', info: '' }
        this.serveSetupForm.servemark.push(obj)
      } else if (type === '2') {
        if (this.afterKey !== '') {
          if (this.serveSetupForm.serveafter.length < 3) {
            this.serveSetupForm.serveafter.push(this.afterKey)
            this.afterKey = ''
          }
        }
      }
    },
    tagDel (type, eq) {
      if (type === '1') {
        this.serveSetupForm.servemark.splice(eq, 1)
      } else if (type === '2') {
        this.serveSetupForm.serveafter.splice(eq, 1)
      }
    },
    // 服务设置[编辑提交]
    handSetConfirm () {
      this.$refs.settingForm.validate((valid) => {
        if (!valid) return
        if (this.serveSetupForm.serveway.buyType !== '0') {
          if (this.serveSetupForm.serveway.buyNumber === '') {
            this.$message.warning('请输入购买数量')
            return
          }
        }
        const params = {
          serveid: this.serveInfoEdit.serveid,
          billway: this.serveSetupForm.billway, // 计费方式 默认1
          serveafter: this.serveSetupForm.serveafter, // 售后服务
          servemark: this.serveSetupForm.servemark, // 购买须知
          servepay: this.checkList, // 支付方式 1微信  2现金 3余额
          serveway: this.serveSetupForm.serveway // 其他设置
        }
        serveSetEdit(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.formSuccess()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.zdyP{
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #3C84EF;
  cursor: pointer;
}
.zdyRow{
  margin-bottom: 20px;
}
.zdyRow:last-child{
  margin-bottom: 0px;
}
.zdyLabel{
  margin-bottom: 8px;
  margin-right: 10px;
  width: 50%;
}
.surDel{
  color: #F44C8E;
  cursor: pointer;
}
.typeRadio{
  width: 100%;
  margin-top: 10px;
}
.spcItem >>> .el-form-item__label {
  width: 100%;
  text-align: left;
}
.tip{
  line-height: 30px;
}
.tip span{
  color: red;
}
</style>
